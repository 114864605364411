.table-component {
  & > .table-body-row {
    @apply border-b border-slate-100;
  }

  &.has-border > .table-body-row:last-child {
    @apply border-none;
  }

  & .table-body-row.selected {
    @apply bg-slate-25 text-slate-900;
  }
}
